import React, { FC, useState } from "react";
import styled from "styled-components";
import { FeatureCollection, Geometry } from "geojson";
import Explanation from "./Explanation";
import { Spinner } from "./utils/Spinner";
import { backendURL } from "./utils/environment";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const TopDiv = styled.div`
  position: absolute;
  left: 24px;
  top: 24px;
  z-index: 70;
  background: #2c2e33;
  border-radius: 3px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  width: 340px;
  color: #e1e6f3;
  border: 2px solid #878c9a;
  @media (max-width: 768px) {
    background: red;
  }
`;

const InnerDiv = styled.div`
  padding: 0px 20px 0px;
`;

const TitleButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const MusicBox = styled.input`
  height: 10px;
  width: 20px;
  display: inline;
  background-color: #f00;
  padding: 15px 15px 15px;
  margin: 27px 0 0;
`;

const Title = styled.h3`
  text-align: left;
  margin: 20px 0 15px;
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 16px -4rem);
`;

const TimeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const TimeTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 10px;
`;
const UnderTitle = styled.h4`
  color: #e1e6f3;
  font-size: 15px;
  margin: 0 0 8px;
`;

const TimeInput = styled.input`
  background-color: #878c9a;
  border: 1px solid #ffffff;
  padding: 7px;
  font-size: 14px;
  border-radius: 4px;
  width: 200px;
  margin: 0rem 0rem 1rem;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: #2c2e33;
  }
`;

const ButtonTimeInput = styled.button`
  background-color: #999;
  border-radius: 6px;
  border: none;
  text-align: left;
  padding: 7px 10px;
  width: 180px;
`;

const ErrorContainer = styled.div`
  margin: 0 0 1rem;
  background-color: #c99;
  border-radius: 6px;
  text-align: center;
  padding: 5px;
`;

const Input = styled.input`
  background-color: #878c9a;
  border: 1px solid #ffffff;
  padding: 6px;
  flex-grow: 2;
  border-radius: 4px;
  margin-bottom: 25px;
  margin-right: 20px;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: #2c2e33;
  }
`;

const InputDrawingContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 300px;
`;

const DrawingButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 15px 0 15px;
`;

const ButtonInputConfirm = styled.button`
  background-color: #ccff00;
  border-radius: 6px;
  font-size: 20px;
  border: none;
  padding: 7px 8px 4px;
  margin: 0px 10px 25px;
`;

const ButtonGeoClip = styled.button`
  background-color: #ccff00;
  border-radius: 6px;
  font-size: 20px;
  border: none;
  padding: 7px 10px 4px;
  margin-right: 20px;
`;

const AOIContainer = styled.div`
  width: 150px;
`;
const ButtonDraw = styled.button`
  background-color: #fff;
  border-radius: 6px;
  border: none;
  font-size: 20px;
  padding: 7px 10px 3px;
  margin-right: 20px;
`;

const ButtonInput = styled.button`
  background-color: #999;
  border-radius: 6px;
  border: none;
  padding: 7px 10px;
  margin-right: 45px;
`;

const ButtonRemove = styled.button`
  background-color: #878c9a;
  border-radius: 6px;
  font-size: 20px;
  border: none;
  padding: 7px 10px 4px;
`;

const ButtonContainerAcq = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 8px;
`;

const ButtonAcqAlone = styled.button`
  background-color: #ccff00;
  margin: 0 20px 20px;
  font-weight: bold;
  border-radius: 6px;
  border: none;
  padding: 10px;
`;

const HeatButton = styled.button`
  background-color: #ee5555;
  border-radius: 6px;
  padding: 10px;
  font-weight: bold;
  border: 2px none #878c9a;
  margin: 0 20px 20px;
`;

const CrystalButton = styled.button`
  background-color: #ffaa44;
  opacity: 0.8;
  border-radius: 6px;
  padding: 10px 10px;
  font-weight: bold;
  border: 2px none #dd8822;
  margin: 0 10px 20px;
`;

const ButtonAcqShared = styled.button`
  background-color: #ccff00;
  border-radius: 3px;
  border: none;
  padding: 10px 5px;
  font-weight: bold;
`;

const StyledDatePickerWrapper = styled.div`
  position: absolute;
  top: 180px;
`;

const ButtonReload = styled.button`
  background-color: #eee;
  border-radius: 6px;
  padding: 10px;
  font-weight: bold;
  border: 2px none #878c9a;
  margin: 0 12px 20px;
`;

const Userdata: FC<{
  mapper: undefined | mapboxgl.Map;
  setDrawMode: (data: boolean) => void;
  sliderClick: (data: string) => void;
  handleRemove: (data: void) => void;
  setArea: (data: string) => void;
  handleHeatClick: (data: string) => void;
  area: string;
  drawMode: boolean;
  polygon: Geometry | null;
  loading: boolean;
  errorMsg: string;
  setFigureInMap: (data: void) => void;
  aquiredData: boolean;
  aquiredHeat: boolean;
  setCrystalMode: (data: boolean) => void;
  setAquiredData: (data: boolean) => void;
  crystalMode: boolean;
}> = ({
  setDrawMode,
  sliderClick,
  handleRemove,
  setArea,
  handleHeatClick,
  area,
  drawMode,
  polygon,
  loading,
  errorMsg,
  setFigureInMap,
  aquiredData,
  aquiredHeat,
  setCrystalMode,
  crystalMode,
  setAquiredData,
}) => {
  const [getDate, setGetDate] = useState<string>("");
  const [displayDate, setDisplayDate] = useState<string>(
    new Date().toLocaleString().split(",")[0]
  );
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [openDatePicker, setOpenDatePicker] = useState<Boolean>(false);

  const [checked, setChecked] = useState<boolean>(false);
  const [inputMode, setInputMode] = useState<boolean>(false);

  const [endTime, setEndTime] = useState<undefined | string>(undefined);
  const [limit, setLimit] = useState<undefined | string>(undefined);
  const [clipboardData, setClipboardData] = useState<
    undefined | FeatureCollection
  >(undefined);

  //let audio = new Audio("/VakeVakeQuiet.mp3");
  // const start = () => {
  //   console.log("Playing audio");
  //   audio.play();
  // };

  const handleGeoClip = () => {
    if (polygon) {
      navigator.clipboard.writeText(JSON.stringify(polygon));
      console.log("Clipboard polygon: ", JSON.stringify(polygon));
    }
  };

  const handleDrawKeypress = (e) => {
    if (e.key === "Enter") {
      setFigureInMap();
      sliderClick(getDate);
    }
  };

  const handleDatePicker = (date) => {
    setOpenDatePicker(!openDatePicker);
    setGetDate(date.toISOString());
    setDisplayDate(date.toLocaleString().split(",")[0]);
    setStartDate(date);
    console.log("date", date);
  };

  const onTimeInput = (dateString: string) => {
    setDisplayDate(dateString);
    console.log("before parsing");
    let date = Date.parse(dateString);
    console.log("after parsing ", date);
    if (!isNaN(date) || date > 1420070400000) {
      //number represents 2015 in milliseconds
      console.log("Inside if");
      let newDate = new Date(dateString);
      setGetDate(newDate.toISOString);
      setStartDate(newDate);
    }
  };

  const handleChange = () => {
    setChecked(!checked);
  };
  const crystalClick = () => {
    setCrystalMode(!crystalMode);
  };

  return (
    <TopDiv>
      <InnerDiv>
        <TitleButtonContainer>
          <Title>Datas for the Hatas d;)</Title>
          <MusicBox type="checkbox" onChange={handleChange}></MusicBox>
        </TitleButtonContainer>
        <Explanation />
        <FlexContainer>
          {errorMsg && <ErrorContainer> {errorMsg}</ErrorContainer>}
          <TimeContainer>
            <TimeTitleContainer>
              <UnderTitle>Start date</UnderTitle>
              <div>
                <ButtonTimeInput
                  onClick={() => setOpenDatePicker(!openDatePicker)}
                >
                  {displayDate}
                </ButtonTimeInput>
                {openDatePicker && (
                  <StyledDatePickerWrapper>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => handleDatePicker(date)}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      inline
                    />
                  </StyledDatePickerWrapper>
                )}
                {(aquiredData || aquiredHeat || crystalMode) && (
                  <ButtonReload onClick={() => window.location.reload()}>
                    Reset page
                  </ButtonReload>
                )}
              </div>
            </TimeTitleContainer>
            {!drawMode && (
              <AOIContainer>
                <UnderTitle>Area of interest</UnderTitle>
                {!(inputMode || drawMode) && (
                  <DrawingButtonContainer>
                    <ButtonDraw onClick={() => setDrawMode(!drawMode)}>
                      {" "}
                      <i className="ph-bounding-box"></i>{" "}
                    </ButtonDraw>
                    <ButtonInput onClick={() => setInputMode(!inputMode)}>
                      {" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                      >
                        <g
                          className="nc-icon-wrapper"
                          strokeLinecap="square"
                          strokeLinejoin="miter"
                          strokeWidth="2"
                          fill="none"
                          stroke="#212121"
                          strokeMiterlimit="10"
                        >
                          <path d="M8.314,19.003 C5.99,16.205,3,12.018,3,9c0-5.062,4.134-8,8-8s8,2.938,8,8c0,0.321-0.034,0.655-0.097,0.999"></path>{" "}
                          <polygon
                            points=" 15,23 12,23 12,20 18,14 21,17 "
                            stroke="#212121"
                          ></polygon>{" "}
                          <circle cx="11" cy="9" r="3"></circle>
                        </g>
                      </svg>{" "}
                    </ButtonInput>
                  </DrawingButtonContainer>
                )}
                {inputMode && (
                  <InputDrawingContainer>
                    <Input
                      value={area}
                      onInput={(e) =>
                        setArea((e.target as HTMLInputElement).value)
                      }
                      onKeyPress={handleDrawKeypress}
                      placeholder="Polygon"
                    />
                    <ButtonInputConfirm onClick={() => setFigureInMap()}>
                      <i className="ph-check"></i>
                    </ButtonInputConfirm>
                  </InputDrawingContainer>
                )}
              </AOIContainer>
            )}
            {drawMode && (
              <div>
                <UnderTitle>Area of interest</UnderTitle>
                <DrawingButtonContainer>
                  <ButtonGeoClip onClick={() => handleGeoClip()}>
                    <i className="ph-clipboard"></i>
                  </ButtonGeoClip>
                  <ButtonRemove onClick={() => handleRemove()}>
                    <i className="ph-x"></i>
                  </ButtonRemove>
                </DrawingButtonContainer>
              </div>
            )}
          </TimeContainer>
        </FlexContainer>
      </InnerDiv>

      {loading && <Spinner />}
      <ButtonContainerAcq>
        <HeatButton onClick={() => handleHeatClick(getDate)}>
          Frequency Heatmap
        </HeatButton>
        {(aquiredData || crystalMode) && (
          <CrystalButton onClick={() => crystalClick()}>
            Crystal Mode
          </CrystalButton>
        )}
        <ButtonAcqAlone onClick={() => sliderClick(getDate)}>
          Aquire Aquisitions
        </ButtonAcqAlone>
      </ButtonContainerAcq>
    </TopDiv>
  );
};

export default Userdata;
