

export const backendURL = () => {
    if (window.location.hostname === "localhost") {
        return "http://localhost:8000"
    }
    else if (window.location.hostname === "react-skraper-frontend.vake-skrape.test.iterate.no") {
        return "https://api.vake-skrape.test.iterate.no"
    } else if (window.location.hostname === "react-skraper-frontend.vake-skrape.app.iterate.no") {
        return "https://api.vake-skrape.app.iterate.no"
    }
}