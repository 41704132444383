import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import Slider from "@mui/material/Slider";
import "@fontsource/ibm-plex-mono";

const SliderContainer = styled.div`
  position: absolute;
  bottom: 10px;
  z-index: 80;
  width: 100%;
  color: #e1e6f3;
`;
const SliderSubContainer = styled.div`
  border-radius: 3px;
  margin: 0 50px 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;
const SliderSubSubContainer = styled.div`
  background: #2c2e33;
  border-radius: 3px;
  padding: 10px 0 10px;
  width: 100%;
`;
const DateText = styled.div`
  background: #2c2e33;
  margin: 0 10px 0;
  padding: 10px 20px 5px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
`;

const TextFont = styled.span`
  border: 1px solid #ffffff;
  padding: 2px 10px 2px;
  margin: 0 10px;
`;

const OuterDates = styled.div`
  display: flex;
  justify-content: space-between;
`;

const OuterDatesText = styled.div`
  margin: 3px 5px 8px;
  border: 1px solid #ffffff;
  padding: 2px 5px 0px;
`;

const OuterDatesContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const ToggleTrackScroll = styled.input`
  height: 10px;
  width: 20px;
  display: inline;
  background-color: #f00;
  padding: 15px 15px 15px;
`;

const ButtonMoreData = styled.button`
  background-color: #ccff00;
  border-radius: 6px;
  font-size: 18px;
  border: none;
  padding: 0px 6px 0px;
  margin: 0 12px 0;
`;

const CrystalSlide: FC<{
  leftCrystal: number;
  rightCrystal: number;
  minCrystal: number;
  maxCrystal: number;
  setLeftCrystal: (data: number) => void;
  setRightCrystal: (data: number) => void;
  filterCrystalDataByDate: (leftCrystal: number, rightCrystal: number) => void;
}> = ({
  leftCrystal,
  rightCrystal,
  minCrystal,
  maxCrystal,
  setLeftCrystal,
  setRightCrystal,
  filterCrystalDataByDate,
}) => {
  const [dateLeftSlide, setDateLeftSlide] = useState<string>("");
  const [dateRightSlide, setDateRightSlide] = useState<string>("");
  const [trackCheck, setTrackCheck] = useState<boolean>(false);

  useEffect(() => {
    let newDate = new Date(leftCrystal);
    setDateLeftSlide(
      newDate.getFullYear() +
        "-" +
        (newDate.getMonth() + 1) +
        "-" +
        newDate.getDate()
    );
    if (minCrystal != 0) {
      filterCrystalDataByDate(leftCrystal, rightCrystal);
    }
  }, [leftCrystal]);

  useEffect(() => {
    let newDate = new Date(rightCrystal);
    setDateRightSlide(
      newDate.getFullYear() +
        "-" +
        (newDate.getMonth() + 1) +
        "-" +
        newDate.getDate()
    );
    if (minCrystal != 0) {
      filterCrystalDataByDate(leftCrystal, rightCrystal);
    }
  }, [rightCrystal]);

  const handleChangeSlider = (event, newValue) => {
    if (trackCheck) {
      const diff = rightCrystal - leftCrystal;
      if (newValue[0] === leftCrystal) {
        setRightCrystal(newValue[1]);
        setLeftCrystal(newValue[1] - diff);
      } else if (newValue[1] === rightCrystal) {
        setLeftCrystal(newValue[0]);
        setRightCrystal(newValue[0] + diff);
      }
    } else {
      setLeftCrystal(newValue[0]);
      setRightCrystal(newValue[1]);
    }
  };

  const handleChange = () => {
    setTrackCheck(!trackCheck);
  };

  return (
    <SliderContainer>
      <SliderSubContainer>
        <DateText>
          Between <TextFont>{dateLeftSlide}</TextFont> and{" "}
          <TextFont>{dateRightSlide}</TextFont>
        </DateText>
        <SliderSubSubContainer>
          <Slider
            value={[leftCrystal, rightCrystal]}
            min={minCrystal}
            max={maxCrystal}
            onChange={handleChangeSlider}
          />
          <OuterDates>
            <OuterDatesContainer>
              <OuterDatesText>
                {new Date(minCrystal).toDateString()}
              </OuterDatesText>
            </OuterDatesContainer>
            <DateText>
              {" "}
              Lock intervall
              <ToggleTrackScroll
                type="checkbox"
                onChange={handleChange}
              ></ToggleTrackScroll>
            </DateText>
            <OuterDatesContainer>
              <OuterDatesText>
                {new Date(maxCrystal).toDateString()}
              </OuterDatesText>
            </OuterDatesContainer>
          </OuterDates>
        </SliderSubSubContainer>
      </SliderSubContainer>
    </SliderContainer>
  );
};

export default CrystalSlide;
