import React, { FC, useEffect, useState } from "react";
import Slider from "@mui/material/Slider";
import styled from "styled-components";
import "@fontsource/ibm-plex-mono";

const SliderContainer = styled.div`
  position: absolute;
  bottom: 10px;
  z-index: 80;
  width: 100%;
  color: #e1e6f3;
`;
const SliderSubContainer = styled.div`
  border-radius: 3px;
  margin: 0 50px 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;
const SliderSubSubContainer = styled.div`
  background: #2c2e33;
  border-radius: 3px;
  padding: 10px 0 10px;
  width: 100%;
`;
const DateText = styled.div`
  background: #2c2e33;
  margin: 0 10px 0;
  padding: 10px 20px 5px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
`;

const TextFont = styled.span`
  border: 1px solid #ffffff;
  padding: 2px 10px 2px;
  margin: 0 10px;
`;

const OuterDates = styled.div`
  display: flex;
  justify-content: space-between;
`;

const OuterDatesText = styled.div`
  margin: 3px 5px 8px;
  border: 1px solid #ffffff;
  padding: 2px 5px 0px;
`;

const OuterDatesContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const ToggleTrackScroll = styled.input`
  height: 10px;
  width: 20px;
  display: inline;
  background-color: #f00;
  padding: 15px 15px 15px;
`;

const ButtonMoreData = styled.button`
  background-color: #ccff00;
  border-radius: 6px;
  font-size: 18px;
  border: none;
  padding: 0px 6px 0px;
  margin: 0 12px 0;
`;

const HeatMapSlide: FC<{
  leftHeat: number;
  rightHeat: number;
  setLeftHeat: (data: number) => void;
  setRightHeat: (data: number) => void;
  //   handleMoreLaterData: (data: void) => void;
  // handleMoreEarlierHeat: (data: void) => void;
  minHeat: number;
  maxHeat: number;
  moreLaterHeat: boolean;
  moreEarlierHeat: boolean;
  filterHeatData: () => void;
}> = ({
  leftHeat,
  rightHeat,
  setLeftHeat,
  setRightHeat,
  //   handleMoreLaterData,
  // handleMoreEarlierHeat,
  minHeat,
  maxHeat,
  moreLaterHeat,
  moreEarlierHeat,
  filterHeatData,
}) => {
  const [dateLeftSlide, setDateLeftSlide] = useState<string>("");
  const [dateRightSlide, setDateRightSlide] = useState<string>("");
  const [trackCheck, setTrackCheck] = useState<boolean>(false);

  useEffect(() => {
    let newDate = new Date(leftHeat);
    setDateLeftSlide(
      newDate.getFullYear() +
        "-" +
        (newDate.getMonth() + 1) +
        "-" +
        newDate.getDate()
    );
  }, [leftHeat, setLeftHeat]);

  useEffect(() => {
    let newDate = new Date(rightHeat);
    setDateRightSlide(
      newDate.getFullYear() +
        "-" +
        (newDate.getMonth() + 1) +
        "-" +
        newDate.getDate()
    );
  }, [rightHeat, setRightHeat]);

  const handleChangeSlider = (event, newValue) => {
    // console.log("Changin slider: ", leftHeat, rightHeat);
    if (trackCheck) {
      const diff = rightHeat - leftHeat;
      if (newValue[0] === leftHeat) {
        setRightHeat(newValue[1]);
        setLeftHeat(newValue[1] - diff);
      } else if (newValue[1] === rightHeat) {
        setLeftHeat(newValue[0]);
        setRightHeat(newValue[0] + diff);
      }
    } else if (newValue[0] !== leftHeat || newValue[1] !== rightHeat) {
      setLeftHeat(newValue[0]);
      setRightHeat(newValue[1]);
      filterHeatData();
    }
  };

  const handleChange = () => {
    setTrackCheck(!trackCheck);
  };

  return (
    <SliderContainer>
      <SliderSubContainer>
        <DateText>
          Between <TextFont>{dateLeftSlide}</TextFont> and{" "}
          <TextFont>{dateRightSlide}</TextFont>
        </DateText>
        <SliderSubSubContainer>
          <Slider
            value={[leftHeat, rightHeat]}
            min={minHeat}
            max={maxHeat}
            color="secondary"
            onChange={handleChangeSlider}
            step={86400000 * 1} //milliseconds in a day
          />
          <OuterDates>
            <OuterDatesContainer>
              {/* {moreEarlierHeat && (
                <ButtonMoreData onClick={() => handleMoreEarlierHeat()}>
                  <i className="ph-arrow-left"></i>
                </ButtonMoreData>
              )} */}
              <OuterDatesText>
                {new Date(minHeat).toDateString()}
              </OuterDatesText>
            </OuterDatesContainer>
            <DateText>
              {" "}
              Lock intervall
              <ToggleTrackScroll
                type="checkbox"
                onChange={handleChange}
              ></ToggleTrackScroll>
            </DateText>
            <OuterDatesContainer>
              <OuterDatesText>
                {new Date(maxHeat).toDateString()}
              </OuterDatesText>
              {/* {moreLaterHeat && (
                <ButtonMoreData onClick={() => handleMoreLaterHeat()}>
                  <i className="ph-arrow-right"></i>
                </ButtonMoreData>
              )} */}
            </OuterDatesContainer>
          </OuterDates>
        </SliderSubSubContainer>
      </SliderSubContainer>
    </SliderContainer>
  );
};

export default HeatMapSlide;
