import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import Slider from "@mui/material/Slider";
import "@fontsource/ibm-plex-mono";

const SliderContainer = styled.div`
  position: absolute;
  bottom: 10px;
  z-index: 80;
  width: 100%;
  color: #e1e6f3;
`;
const SliderSubContainer = styled.div`
  border-radius: 3px;
  margin: 0 50px 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;
const SliderSubSubContainer = styled.div`
  background: #2c2e33;
  border-radius: 3px;
  padding: 10px 0 10px;
  width: 100%;
`;
const DateText = styled.div`
  background: #2c2e33;
  margin: 0 10px 0;
  padding: 10px 20px 5px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
`;

const TextFont = styled.span`
  border: 1px solid #ffffff;
  padding: 2px 10px 2px;
  margin: 0 10px;
`;

const OuterDates = styled.div`
  display: flex;
  justify-content: space-between;
`;

const OuterDatesText = styled.div`
  margin: 3px 5px 8px;
  border: 1px solid #ffffff;
  padding: 2px 5px 0px;
`;

const OuterDatesContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const ToggleTrackScroll = styled.input`
  height: 10px;
  width: 20px;
  display: inline;
  background-color: #f00;
  padding: 15px 15px 15px;
`;

const ButtonMoreData = styled.button`
  background-color: #ccff00;
  border-radius: 6px;
  font-size: 18px;
  border: none;
  padding: 0px 6px 0px;
  margin: 0 12px 0;
`;

const Slide: FC<{
  leftSlide: number;
  rightSlide: number;
  setLeftSlide: (data: number) => void;
  setRightSlide: (data: number) => void;
  sliderClick: (data: string) => void;
  handleMoreLaterData: (data: void) => void;
  handleMoreEarlierData: (data: void) => void;
  minSlide: number;
  maxSlide: number;
  loading: boolean;
  moreLaterData: boolean;
  moreEarlierData: boolean;
}> = ({
  leftSlide,
  rightSlide,
  setLeftSlide,
  setRightSlide,
  handleMoreLaterData,
  handleMoreEarlierData,
  minSlide,
  maxSlide,
  moreLaterData,
  moreEarlierData,
}) => {
  const [dateLeftSlide, setDateLeftSlide] = useState<string>("");
  const [dateRightSlide, setDateRightSlide] = useState<string>("");
  const [trackCheck, setTrackCheck] = useState<boolean>(false);

  useEffect(() => {
    let newDate = new Date(leftSlide);
    setDateLeftSlide(
      newDate.getFullYear() +
        "-" +
        (newDate.getMonth() + 1) +
        "-" +
        newDate.getDate()
    );
  }, [leftSlide, setLeftSlide]);

  useEffect(() => {
    let newDate = new Date(rightSlide);
    setDateRightSlide(
      newDate.getFullYear() +
        "-" +
        (newDate.getMonth() + 1) +
        "-" +
        newDate.getDate()
    );
  }, [rightSlide, setRightSlide]);

  const handleChangeSlider = (event, newValue) => {
    if (trackCheck) {
      const diff = rightSlide - leftSlide;
      if (newValue[0] === leftSlide) {
        setRightSlide(newValue[1]);
        setLeftSlide(newValue[1] - diff);
      } else if (newValue[1] === rightSlide) {
        setLeftSlide(newValue[0]);
        setRightSlide(newValue[0] + diff);
      }
    } else {
      setLeftSlide(newValue[0]);
      setRightSlide(newValue[1]);
    }
  };

  const handleChange = () => {
    setTrackCheck(!trackCheck);
  };

  return (
    <SliderContainer>
      <SliderSubContainer>
        <DateText>
          Between <TextFont>{dateLeftSlide}</TextFont> and{" "}
          <TextFont>{dateRightSlide}</TextFont>
        </DateText>
        <SliderSubSubContainer>
          <Slider
            value={[leftSlide, rightSlide]}
            min={minSlide}
            max={maxSlide}
            onChange={handleChangeSlider}
          />
          <OuterDates>
            <OuterDatesContainer>
              {moreEarlierData && (
                <ButtonMoreData onClick={() => handleMoreEarlierData()}>
                  <i className="ph-arrow-left"></i>
                </ButtonMoreData>
              )}
              <OuterDatesText>
                {new Date(minSlide).toDateString()}
              </OuterDatesText>
            </OuterDatesContainer>
            <DateText>
              {" "}
              Lock intervall
              <ToggleTrackScroll
                type="checkbox"
                onChange={handleChange}
              ></ToggleTrackScroll>
            </DateText>
            <OuterDatesContainer>
              <OuterDatesText>
                {new Date(maxSlide).toDateString()}
              </OuterDatesText>
              {moreLaterData && (
                <ButtonMoreData onClick={() => handleMoreLaterData()}>
                  {" "}
                  <i className="ph-arrow-right"></i>
                </ButtonMoreData>
              )}
            </OuterDatesContainer>
          </OuterDates>
        </SliderSubSubContainer>
      </SliderSubContainer>
    </SliderContainer>
  );
};

export default Slide;
