import React, { FC } from "react";
import styled from "styled-components";

const LegendContainer = styled.div`
  position: absolute;
  top: 30%;
  border-radius: 3px;
  left: 90%;
  width: 90px;
  z-index: 80;
  border: 2px solid #878c9a;
  color: #e1e6f3;
  background: #2c2e33;
`;

const LegendTitel = styled.h4`
  margin-left: 7px;
`;

const LegendElement = styled.div`
  display: flex;
  background-color: #878c9a;
  margin: 5px 7px 5px;
  padding: 5px 0 7px;
`;

const LegendColor = styled.div`
  height: 13px;
  width: 13px;
  border-radius: 0.7px;

  background-color: ${({ inputColor }) => inputColor || "#000"};
  margin: 4.5px 5px 0;
`;

const HeatLegend: FC<{ minHeatValue: number; maxHeatValue: number }> = ({
  minHeatValue,
  maxHeatValue,
}) => {
  return (
    <LegendContainer>
      <LegendTitel>Legend</LegendTitel>
      <LegendElement>
        <LegendColor inputColor={"#CCFF00"} />
        {maxHeatValue}
      </LegendElement>
      <LegendElement>
        <LegendColor />
        {minHeatValue}
      </LegendElement>
    </LegendContainer>
  );
};

export default HeatLegend;
